import {useAppPayload} from '@github-ui/react-core/use-app-payload'
import {verifiedFetch} from '@github-ui/verified-fetch'
import {Label, Link} from '@primer/react'
import {BetaLabel} from '@github-ui/lifecycle-labels/beta'
import classes from './CallToActionItem.module.css'

import {useCallback} from 'react'
import type {AppPayload} from '../types/app-payload'
import {LABELS} from '../constants/labels'
import {BUTTON_LABELS} from '../constants/buttons'
import {isFeatureEnabled} from '@github-ui/feature-flags'

type CallToActionProps = {
  optOutUrl: string
  showBetaPill?: boolean
}

export function CallToActionItem({optOutUrl, showBetaPill}: CallToActionProps) {
  const {scoped_repository, feedback_url: feedbackUrl, proxima, render_opt_out} = useAppPayload<AppPayload>()
  const enabled = isFeatureEnabled('lifecycle_label_name_updates')

  const optOut = useCallback(async () => {
    await verifiedFetch(optOutUrl, {method: 'POST'})
    window.location.reload()
  }, [optOutUrl])

  return (
    <div className={classes.wrapper}>
      {showBetaPill && (enabled ? <BetaLabel /> : <Label variant="success">{LABELS.beta}</Label>)}
      {feedbackUrl?.length > 0 && !proxima && (
        <Link href={feedbackUrl} target="_blank" className={classes.feedbackLink}>
          {BUTTON_LABELS.sendFeedback}
        </Link>
      )}
      {render_opt_out && (
        <>
          <span>{scoped_repository ? '·' : '/'}</span>
          <Link as="button" type="button" onClick={optOut}>
            {BUTTON_LABELS.optOut}
          </Link>
        </>
      )}
    </div>
  )
}

try{ CallToActionItem.displayName ||= 'CallToActionItem' } catch {}